import { useState } from "react"
import { DeleteCountdown } from "./api/api"
import { useCountdown } from "./useCountdown"
import { ICountdown } from "./types"

interface CountdownTimerProps {
    countdown: ICountdown
    reloadCountdowns: React.Dispatch<React.SetStateAction<Date>>
}

const CountdownTimer = (props: CountdownTimerProps) => {

    const [errorMessage, setErrorMessage] = useState<string>('')
    const [days, hours, minutes, seconds] = useCountdown(props.countdown.date)

    const clickDeleteTimer = async () => {
        const response = await DeleteCountdown(props.countdown)
        if (!response.success) {
            if (response.errorMessage) {
                setErrorMessage(response.errorMessage)
            } else {
                setErrorMessage("There was an error submitting your countdown")
            }
            return
        }
        props.reloadCountdowns(new Date())
    }

    return <section>
        <div className="container mx-auto py-4">
            <div className="py-2">
                <h2 className="text-center text-xl">{props.countdown.name}</h2>
            </div>
            <div className="flex items-center justify-center w-full gap-1.5 count-down-main">
                <div className="timer">
                    <div className="rounded-xl bg-gradient-to-b from-indigo-600 to-purple-600 py-3 min-w-[96px] flex items-center justify-center flex-col gap-1 aspect-square px-3">
                        <h3 className="countdown-element days font-manrope font-semibold text-2xl text-white text-center">
                            {days}
                        </h3>
                        <p className="text-lg font-manrope font-normal text-white mt-1 text-center w-full">days</p>
                    </div>
                </div>
                <h3 className="font-manrope font-semibold text-2xl text-gray-900">:</h3>
                <div className="timer">
                    <div className="rounded-xl bg-gradient-to-b from-indigo-600 to-purple-600 py-3 min-w-[96px] flex items-center justify-center flex-col gap-1 aspect-square px-3">
                        <h3 className="countdown-element hours font-manrope font-semibold text-2xl text-white text-center">
                            {hours}
                        </h3>
                        <p className="text-lg font-manrope font-normal text-white mt-1 text-center w-full">Hour</p>
                    </div>
                </div>
                <h3 className="font-manrope font-semibold text-2xl text-gray-900">:</h3>
                <div className="timer">
                    <div className="rounded-xl bg-gradient-to-b from-indigo-600 to-purple-600 py-3 min-w-[96px] flex items-center justify-center flex-col gap-1 aspect-square px-3">
                        <h3 className="countdown-element minutes font-manrope font-semibold text-2xl text-white text-center">
                            {minutes}
                        </h3>
                        <p className="text-lg font-manrope font-normal text-white mt-1 text-center w-full">Minutes</p>
                    </div>
                </div>
                <h3 className="font-manrope font-semibold text-2xl text-gray-900">:</h3>
                <div className="timer">
                    <div className="rounded-xl bg-gradient-to-b from-indigo-600 to-purple-600 py-3 min-w-[96px] flex items-center justify-center flex-col gap-1 aspect-square px-3">
                        <h3 className="countdown-element seconds font-manrope font-semibold text-2xl text-white text-center">
                            {seconds}
                        </h3>
                        <p className="text-lg font-manrope font-normal text-white mt-1 text-center w-full">Seconds</p>
                    </div>
                </div>
                <div className=" flex items-center group" >
                <button onClick={() => clickDeleteTimer()} type='button' className ='w-9 h-9  bg-black ring-red-400 rounded-full flex items-center justify-center cursor-pointer transition-all duration-500  hover:bg-red-400'>
                    <svg width='10' height='10' viewBox='0 0 10 10' fill='none' overflow="visible"  stroke='white' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round' xmlns='http://www.w3.org/2000/svg'>
                        <line x1="0" y1="0" x2="10" y2="10" />
                        <line x1="10" y1="0" x2="0" y2="10" />
                    </svg>
                </button>
                <span className="group-hover:opacity-100 transition-opacity bg-gray-700 mx-3 p-1 text-sm text-gray-100 rounded-md opacity-0 m-4 mx-auto">Delete</span>
                </div>
            </div>
        </div>
        
    </section>
}

export default CountdownTimer;