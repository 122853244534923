
import { CognitoIdentityProviderClient, InitiateAuthCommand, InitiateAuthCommandInput } from "@aws-sdk/client-cognito-identity-provider";
import { config } from "./awsConfig";
import React, { useState } from "react";
import { NavigateFunction, useNavigate } from "react-router";
import { ICognitoIdToken } from "./types";

export const cognitoClient = new CognitoIdentityProviderClient({
    region: config.region,
});

interface LoginProps {

}

interface LoginState {
    username: string,
    password: string,
    errorMessage: string
}

const Login = (props: LoginProps) => {
        const [username, setUsername] = useState('')
        const [password, setPassword] = useState('')
        const [errorMessage, setErrorMessage] = useState('')


    const navigate: NavigateFunction = useNavigate  ();

    const signIn = async (username: string, password: string) => {
        console.log("called signIn")
        const params: InitiateAuthCommandInput = {
            AuthFlow: "USER_PASSWORD_AUTH",
            ClientId: config.clientId,
            AuthParameters: {
                USERNAME: username,
                PASSWORD: password,
            },
        };
        try {
            const command = new InitiateAuthCommand(params);
            const { AuthenticationResult } = await cognitoClient.send(command);
            if (AuthenticationResult) {
                sessionStorage.setItem("idToken", AuthenticationResult.IdToken || '');
                sessionStorage.setItem("accessToken", AuthenticationResult.AccessToken || '');
                sessionStorage.setItem("refreshToken", AuthenticationResult.RefreshToken || '');
                sessionStorage.setItem("username", getUsername())
                return AuthenticationResult;
            }
        } catch (error: unknown) {
            console.error("Error signing in: ", error);
            console.log(typeof error)
            console.log(error)
            let message = 'Unknown error, go get Dad'
            if (error instanceof Error) message = error.message
            //this.setState({ errorMessage: message as string })
            setErrorMessage(message)
        }
        
    };

    const getUsername = (): string => {
        const idToken = sessionStorage.getItem("idToken")
        if (idToken !== null) {
            const decodedToken: ICognitoIdToken = parseJwt(idToken)
            return decodedToken["cognito:username"]
        } else {
            return ''
        }
    }

    const parseJwt = (token: string) => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
    }

    const handleInputUsername = (event: any) => {
        //this.setState({ username: event.target.value })
        setUsername(event.target.value)
    }

    const handleInputPassword = (event: any) => {
        //this.setState({ password: event.target.value })
        setPassword(event.target.value)
    }

    const handleLoginClick = async (_event: any) => {
        await signIn(username, password);
        navigate("/") //TODO: not working now
    }

    return (
<section className="bg-dark py-14 lg:py-20">
<div className="container mx-auto">
  <div className="flex flex-wrap -mx-4">
    <div className="w-full px-4">
      <div
        className="wow fadeInUp relative mx-auto max-w-[525px] overflow-hidden rounded-lg bg-dark py-14 px-8 text-center sm:px-12 md:px-[60px]"
        data-wow-delay=".15s">
          <div className="mb-[22px]">
            <input type="text" placeholder="Username" value={username} onChange={handleInputUsername}
              className="w-full px-5 py-3 text-base transition bg-transparent border rounded-md outline-none border-stroke text-body-color placeholder:text-dark-6 focus:border-primary focus-visible:shadow-none" />
          </div>
          <div className="mb-[22px]">
            <input type="password" placeholder="Password" value={password} onChange={handleInputPassword}
              className="w-full px-5 py-3 text-base transition bg-transparent border rounded-md outline-none border-stroke text-body-color placeholder:text-dark-6 focus:border-primary focus-visible:shadow-none" />
          </div>
          <div className="mb-9">
            <input type="button" value="Log In" onClick={handleLoginClick} 
              className="w-full px-5 py-3 text-base text-white transition duration-300 ease-in-out border rounded-md cursor-pointer border-primary bg-primary hover:bg-blue-dark" />
          </div>
      </div>
    </div>
  </div>
</div>
</section>
    );
}

export default Login