import React from 'react';
import Login from './Login';
import Navbar from './Navbar';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Landing from './Landing';
import AuthenticatedRoute from './AuthenticatedRoute';
import DinnerMenu from './DinnerMenu';
import DinnerVote from './DinnerVote';
import Meals from './Meals';
import PocketMoney from './PocketMoney';
import Countdown from './Countdowns';

const GetLoginElement = () => {
  if (sessionStorage.getItem("accessToken")) {
    return <Navigate to="/" />;
  }
  return <Login />
}

const App =() => {
  return (
    <div className="App">
      <BrowserRouter basename="/">
      <header className="App-header">
        <nav>
          <Navbar />
        </nav>
      </header>
        <Routes>
          <Route path="/login" element={GetLoginElement()} />
          <Route element={<AuthenticatedRoute />} >
            <Route path="/" element={<Landing />} />
          </Route>
          <Route element={<AuthenticatedRoute />} >
            <Route path="/meals" element={<Meals />} />
          </Route>
          <Route element={<AuthenticatedRoute />} >
            <Route path="/dinner" element={<DinnerMenu />} />
          </Route>
          <Route element={<AuthenticatedRoute />} >
            <Route path="/dinnervote" element={<DinnerVote />} />
          </Route>
          <Route element={<AuthenticatedRoute />} >
            <Route path="/pocketmoney" element={<PocketMoney />} />
          </Route>
          <Route element={<AuthenticatedRoute />} >
            <Route path="/timeuntil" element={<Countdown />} />
          </Route>
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
