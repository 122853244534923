import React, { useEffect, useState } from "react";
import { IMeal, IWeeksDinners } from "./types";
import { appendFile } from "fs";
import { fetchMeals } from "./api/api";
import MealBox from "./MealBox";

const mealsExample: IMeal[] = [
    {
      "name": "exampleMeal1"
    },
    {
      "name": "exampleMeal2"
    },
    {
      "name": "exampleMeal3"
    },
    {
      "name": "exampleMeal4"
    }
]

interface MealItemProps {
    meal: IMeal
}

const MealItem = (props: MealItemProps) => {

    return <div className="flex flex-row">
        <div className="basis-2/3 text-center">{props.meal.name}</div>
        </div>
}

interface MealsProps {
    //weeksDinners: IWeeksDinners
}

const Meals = (props: MealsProps) => {

    const [errorMessage, setErrorMessage] = useState<string>('')
    const [meals, setMeals] = useState<IMeal[]>([])

    useEffect(() => {
        async function fetchData() {
            const fetchMealsResponse = await fetchMeals();
            if (fetchMealsResponse.errorMessage) {
              setErrorMessage(fetchMealsResponse.errorMessage)
            }
            if (fetchMealsResponse.meals) {
              setMeals(fetchMealsResponse.meals)
            }
        }
        fetchData()
    }, [])

    let jsx;

    if (errorMessage && errorMessage.trim() !== "") {
      console.log("dandebug returning error message")
      jsx = <div>
        <p>There was an error retreiving meals</p>
        <p>{errorMessage}</p>
      </div>
    } else {
      jsx = <>
      <section className="py-14">
        <div className="container mx-auto">
          <div className="flex flex-wrap justify-center -mx-4">
            {meals.length ? meals.map(m => <MealBox meal={m} />) : <p>There should be meals here</p>}
          </div>
        </div>
      </section>
      </>
    }

    return jsx
    
}

export default Meals