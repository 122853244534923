import React, { useEffect, useState } from "react";
import { IMeal, IWeeksDinners } from "./types";
import { fetchDinnersForWeek } from "./api/api";
import { convertDateFormatForApi, firstDayOfLastWeek, firstDayOfNextWeek, firstDayOfThisWeek } from "./util";

const dinnerStateExample: IWeeksDinners = {
    weekStartDate: "23/2/2024",
    dinners: {
        monday: {
            name: "butter chicken"
        },
        tuesday: {
            name: "Burgers"
        },
        wednesday: {
            name: "macaroni cheese"
        },
        thursday: {
            name: "fried rice"
        },
        friday: {
            name: "pasta and mince"
        },
        saturday: {
            name: "bangers and mash"
        },
        sunday: {
            name: "crispy chicken wraps"
        },
    }
}

interface DinnerDayItemProps {
    day: string
    meal: IMeal | undefined
}

const DinnerDayItem = (props: DinnerDayItemProps) => {

    return <div className="flex flex-row">
        <div className="basis-1/3 text-right mx-2">{props.day}</div>
        <div className="basis-2/3 text-left mx-2">{props.meal?.name}</div>
        </div>
}

interface WeeksDinnerItemProps {
    weeksDiners: IWeeksDinners | undefined
}

const WeeksDinnersItem = (props: WeeksDinnerItemProps) => {
   return <>
        <DinnerDayItem day="Monday:" meal={props.weeksDiners?.dinners.monday} />
        <DinnerDayItem day="Tuesday:" meal={props.weeksDiners?.dinners.tuesday} />
        <DinnerDayItem day="Wednesday:" meal={props.weeksDiners?.dinners.wednesday} />
        <DinnerDayItem day="Thursday:" meal={props.weeksDiners?.dinners.thursday} />
        <DinnerDayItem day="Friday:" meal={props.weeksDiners?.dinners.friday} />
        <DinnerDayItem day="Saturday:" meal={props.weeksDiners?.dinners.saturday} />
        <DinnerDayItem day="Sunday:" meal={props.weeksDiners?.dinners.sunday} />
    </>
}

interface DinnerMenuProps {
    //weeksDinners: IWeeksDinners
}

const DinnerMenu = (props: DinnerMenuProps) => {

    const [errorMessage, setErrorMessage] = useState<string>('')
    const [thisWeeksDinners, setThisWeeksDinners] = useState<IWeeksDinners>()
    const [lastWeeksDinners, setLastWeeksDinners] = useState<IWeeksDinners>()
    const [nextWeeksDinners, setNextWeeksDinners] = useState<IWeeksDinners>()
    const [dinnerSelectorState, setDinnerSelectorState] = useState<string>('This week')

    useEffect(() => {
        async function fetchDinners(date: string, setFunc: Function) {
            console.log(`fetchDinners called with ${date} and ${setFunc}`)
            const fetchWeeksDinnersResponse = await fetchDinnersForWeek(date);
            if (fetchWeeksDinnersResponse.errorMessage) {
              setErrorMessage(fetchWeeksDinnersResponse.errorMessage)
            }
            if (fetchWeeksDinnersResponse.weeksDinners) {
                setFunc(fetchWeeksDinnersResponse.weeksDinners)
            }
        }
        const firstDayOfNextWeekDate = convertDateFormatForApi(firstDayOfNextWeek(new Date, 1))
        fetchDinners(firstDayOfNextWeekDate, setNextWeeksDinners)

        const firstDayOfThisWeekDate = convertDateFormatForApi(firstDayOfThisWeek(new Date, 1))
        fetchDinners(firstDayOfThisWeekDate, setThisWeeksDinners)

        const firstDayOfLastWeekDate = convertDateFormatForApi(firstDayOfLastWeek(new Date, 1))
        fetchDinners(firstDayOfLastWeekDate, setLastWeeksDinners)

    }, [])

    const selectDinnerWeek = async (week: string) => {
        setDinnerSelectorState(week)
    }

    const getWeeksDinners = (): IWeeksDinners|undefined => {
        // TODO: be better
        if (dinnerSelectorState == 'This week') {
            return thisWeeksDinners
        } else if (dinnerSelectorState ==  'Next week') {
            return nextWeeksDinners
        } else if (dinnerSelectorState == 'Last week') {
            return lastWeeksDinners
        } else {
            return undefined
        }
    }
    
    return <div className="container mx-auto max-w-[325px]">
        
        <div className="inline-flex rounded-md shadow-sm py-4" role="group">
            <button type="button" className="px-4 py-2 text-sm font-medium border rounded-s-lg focus:z-10 focus:ring-2 bg-gray-800 border-gray-700 text-white hover:text-white hover:bg-gray-700 focus:ring-blue-500 focus:text-white"
                onClick={() => selectDinnerWeek("Last week")}>Last week</button>
            <button type="button" className="px-4 py-2 text-sm font-medium border-t border-b focus:z-10 focus:ring-2 bg-gray-800 border-gray-700 text-white hover:text-white hover:bg-gray-700 focus:ring-blue-500 focus:text-white"
                onClick={() => selectDinnerWeek("This week")}>This week</button>
            <button type="button" className="px-4 py-2 text-sm font-medium border rounded-e-lg  focus:z-10 focus:ring-2 bg-gray-800 border-gray-700 text-white hover:text-white hover:bg-gray-700 focus:ring-blue-500 focus:text-white"
                onClick={() => selectDinnerWeek("Next week")}>Next week</button>
        </div>
        <div className="py-4">Dinners for week starting: {getWeeksDinners()?.weekStartDate}</div>

        {getWeeksDinners() !==undefined ? 
            <WeeksDinnersItem weeksDiners={getWeeksDinners()} />
            : <></>
        }
        
    </div>
}

export default DinnerMenu