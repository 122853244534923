import { useEffect } from "react"
import { IMeal } from "./types"

interface DinnerVoteSelectProps {
    meals: IMeal[] | undefined
    voteIndex:  number
    votes: IMeal[]
    setVotes: React.Dispatch<React.SetStateAction<IMeal[]>>
}

const DinnerVoteSelect = (props: DinnerVoteSelectProps) => {

    const getMealFromArray = (mealName: string) => {
        console.log("dandebug - called getMealFromArray")
        return props.meals?.find(m => m.name === mealName)
    }

    const setSelectedVote = (voteNum: number, e: string) => {

        console.log(`dandebug - called setSelectedVote, voteNumber: '${voteNum}', e: '${e}' `)

        if (e === '') {
            //TODO: allow unselecting
        }

        const meal = getMealFromArray(e);
        if (!meal) {
            return
        }

        const nextVotes = props.votes.map((v, i) => {
            return v
        })
        nextVotes[voteNum] = meal
        props.setVotes(nextVotes);
    }

    const getVoteOptions = (voteNum: number) => {
        console.log("dandebug - getVoteOptions called")
        if (props.meals) {
            const filteredMeals = props.meals.filter((m) => {
                let shouldInclude = true
                props.votes.forEach((v, i) => {

                    if (v.name === m.name) {
                        if (voteNum === i) {
                            shouldInclude = true
                            return
                        }
                        shouldInclude = false
                        return
                    }
                })
                return shouldInclude
            })

            return filteredMeals.map(m => <option value={m.name}>{m.name}</option>)
        } else {
            return <></>
        }
    }

    return  <div className="py-5">
            <label htmlFor={"vote" + props.voteIndex} className="">{"Vote " + (props.voteIndex + 1)}</label>
            <select id={"vote" + props.voteIndex}  value={props.votes[props.voteIndex]?.name || ''} onChange={e => setSelectedVote(props.voteIndex, e.target.value)}
                className="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500">
                <option></option>
                {getVoteOptions(props.voteIndex)}
            </select>
    </div>
}

export default DinnerVoteSelect