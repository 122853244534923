import { useEffect, useState } from "react"
import { fetchPockeyMoneyBalance, fetchPockeyMoneyTransactions } from "./api/api"
import { LineItem } from "./types"

interface IPocketMoneyTransactionsProps {
    transactions: LineItem[]
}

const PocketMoneyTransactions = (props: IPocketMoneyTransactionsProps) => {
    const reversed = [...props.transactions].reverse()
    return <>
    <section className="py-10">
        <div className="container mx-auto">
            <table className="table-auto mx-auto" >
                <thead className="" >
                    <tr>
                        <th scope="col" className="">Date</th>
                        <th scope="col" className="">Description</th>
                        <th scope="col" className="">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {reversed.map(t => 
                        <tr>
                            <td className="px-6 py-2">{t.dateTime?.toDateString()}</td>
                            <td className="px-6 py-2">{t.description}</td>
                            <td className="px-6 py-2">${t.amount}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    </section>
    </>
}

export default PocketMoneyTransactions