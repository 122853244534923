export const getTodaysUtcDate = () => {

}

// Finds the start date of the week, using monday as the first day
export const getThisWeeksStartDate = () => {

    const today = new Date()
    const day = today.getDay() || 7 // converts sunday to 7

    const date = new Date(today)
    date.setDate(today.getDate() - day)
    date.setHours(0,0,0,0)

    return date
}

export function firstDayOfLastWeek(dateObject: Date, firstDayOfWeekIndex: number) {

    const dayOfWeek = dateObject.getDay(),
        firstDayOfWeek = new Date(dateObject),
        diff = dayOfWeek >= firstDayOfWeekIndex ?
            dayOfWeek - firstDayOfWeekIndex :
            6 - dayOfWeek

    firstDayOfWeek.setDate(dateObject.getDate() - diff - 7) // we'll just do this for now
    firstDayOfWeek.setHours(0,0,0,0)

    return firstDayOfWeek
}

export function firstDayOfThisWeek(dateObject: Date, firstDayOfWeekIndex: number) {

    const dayOfWeek = dateObject.getDay(),
        firstDayOfWeek = new Date(dateObject),
        diff = dayOfWeek >= firstDayOfWeekIndex ?
            dayOfWeek - firstDayOfWeekIndex :
            6 - dayOfWeek

    firstDayOfWeek.setDate(dateObject.getDate() - diff)
    firstDayOfWeek.setHours(0,0,0,0)

    return firstDayOfWeek
}

export function firstDayOfNextWeek(dateObject: Date, firstDayOfWeekIndex: number) {

    const dayOfWeek = dateObject.getDay(),
        firstDayOfWeek = new Date(dateObject),
        diff = dayOfWeek >= firstDayOfWeekIndex ?
            dayOfWeek - firstDayOfWeekIndex :
            6 - dayOfWeek

    firstDayOfWeek.setDate(dateObject.getDate() - diff + 7) // we'll just do this for now
    firstDayOfWeek.setHours(0,0,0,0)

    return firstDayOfWeek
}

export const convertDateFormatForApi = (date: Date): string => {

    return `${date.getFullYear()}_${date.getMonth() + 1}_${date.getDate()}`
}
