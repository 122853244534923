import { IMeal } from "./types"

interface IMealBoxProps {
    meal: IMeal
}

const MealBox = (props: IMealBoxProps) => {

    return <>
        <div className="w-full px-4 sm:w-1/2 lg:w-1/4 xl:w-1/4">
            <div
              className="px-5 pt-12 pb-10 mb-8 bg-gray-700 group rounded-xl shadow-testimonial"
            >
              <div className="relative z-10 mx-auto mb-5 h-[120px] w-[120px]">
                <img
                  src="./assets/images/team/team-01.png"
                  alt="meal image to come"
                  className="h-[120px] w-[120px] rounded-full"
                />
              </div>
              <div className="text-center">
                <h4 className="mb-1 text-lg font-semibold text-dark dark:text-white" >
                  {props?.meal?.name || "MEAL IS MISSING A NAME"}
                </h4>
              </div>
            </div>
          </div>
          
    </>
}

export default MealBox
