import { useEffect, useState } from "react"
import CountdownTimer from "./CountdownTimer"
import { fetchAllCountdowns } from "./api/api"
import { ICountdown } from "./types"
import CountdownAdd from "./CountdownAdd"

interface CountdownProps {

}

const Countdown = (props: CountdownProps) => {

    const [errorMessage, setErrorMessage] = useState<string>('')
    const [countdowns, setCountdowns] = useState<ICountdown[]>()
    const [reloadCountdowns, setReloadCountdowns] = useState<Date>(new Date())

    useEffect(() => {
        async function fetchCountDowns() {
            const countdownsResponse = await fetchAllCountdowns()
            if (countdownsResponse.errorMessage) {
                setErrorMessage(countdownsResponse.errorMessage)
            }
            if (countdownsResponse.countdowns) {
                const sorted = countdownsResponse.countdowns.sort((a, b) => a.date > b.date ? 1 : -1)
                setCountdowns(countdownsResponse.countdowns)
            }
        }

        fetchCountDowns()
    }, [reloadCountdowns])

    return <section>
        <CountdownAdd reloadCountdowns={setReloadCountdowns}/>
        {countdowns?.map(c => <CountdownTimer countdown={c} reloadCountdowns={setReloadCountdowns}/>)}
    </section>
}

export default Countdown;