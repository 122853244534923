import React, { useEffect, useState } from "react";
import { IMeal, IVote } from "./types";
import { fetchMeals, fetchVote, postDinnerVotes } from "./api/api";
import { convertDateFormatForApi, firstDayOfNextWeek, firstDayOfThisWeek } from "./util";
import DinnerVoteSelect from "./DinnerVoteSelect";

interface DinnerVoteProps {

}

const DinnerVote = (props: DinnerVoteProps) => {

    const nextWeekStartDateString = convertDateFormatForApi(firstDayOfNextWeek(new Date(), 1))
    const thisWeekStartDateString = convertDateFormatForApi(firstDayOfThisWeek(new Date(), 1))

    const [errorMessage, setErrorMessage] = useState<string>('')
    const [meals, setMeals] = useState<IMeal[]>()
    const [mealsThisWeek, setMealsThisWeek] = useState<IMeal[]>()
    const [votes, setVotes] = useState<IMeal[]>(new Array(3))
    const [votesThisWeek, setVotesThisWeek] = useState<IMeal[]>(new Array(3))
    const [voted, setVoted] = useState<boolean>(false)
    const [votedThisWeek, setVotedThisWeek] = useState<boolean>(false)
    const [isSubmittingVotes, setIsSubmittingVotes] = useState<boolean>(false)
    const [selectedWeek, setSelectedWeek] = useState<string>('')

    useEffect(() => {
        async function fetchMealsData() {
            const fetchMealsResponse = await fetchMeals();
            if (fetchMealsResponse.errorMessage) {
              setErrorMessage(fetchMealsResponse.errorMessage)
            }
            if (fetchMealsResponse.meals) {
              setMeals(fetchMealsResponse.meals)
            }
        }

        async function fetchVotes(weekStartDate: string, setVotesFunc: Function, setVotedFunc: Function) {
            console.log("dandebug - fetching current votes")
            const mondayDate = firstDayOfNextWeek(new Date(), 1)
            const dateString = convertDateFormatForApi(mondayDate)
            const username = sessionStorage.getItem("username") || 'notfound'
            const fetchVoteResponse = await fetchVote(dateString, username)

            if (fetchVoteResponse.errorMessage) {
                setErrorMessage(fetchVoteResponse.errorMessage)
            }
            if (fetchVoteResponse.vote) {
                // set current votes
                setVotesFunc(fetchVoteResponse.vote.meals)
                setVotedFunc(true)
                console.log(fetchVoteResponse.vote)
            }
        }

        fetchMealsData()
        fetchVotes(nextWeekStartDateString, setVotes, setVoted)
        fetchVotes(thisWeekStartDateString, setVotedThisWeek, setVotedThisWeek)

        // set default selected week
        setSelectedWeek(nextWeekStartDateString)
    }, [])

    const submitVotes = async () => {
        console.log("dandebug - called submitVotes")

        const v = selectedWeek === thisWeekStartDateString ? votesThisWeek : votes

        if (v.some(m => m === undefined)) {
            setErrorMessage("Must select all votes")
        }

        const username = sessionStorage.getItem("username")

        if (username === null ) {
            setErrorMessage("Problem retrieving your username, please log out and back in")
            return
        }

        const newVotes: IVote = {
            weekStartDate: selectedWeek,
            meals: v.map(m => m),
            user: username
        }

        const response = await postDinnerVotes(newVotes)
        if (!response.success) {
            if (response.errorMessage) {
                setErrorMessage(response.errorMessage)
            } else {
                setErrorMessage("There was an error submitting your votes")
            }
        }
        if (selectedWeek === thisWeekStartDateString) {
            setVotedThisWeek(true)
        }
        setVoted(true)
    }


    const getVoteFuncForWeek = (): React.Dispatch<React.SetStateAction<IMeal[]>> => {
        if (selectedWeek === thisWeekStartDateString) {
            return setVotesThisWeek
        }
        return setVotes
    }

    const getVotesForWeek = (): IMeal[] => {
        if (selectedWeek === thisWeekStartDateString) {
            return votesThisWeek
        }
        return votes
    }


    return <section className="py-14">
    <div className="container mx-auto max-w-[325px]">
        <div className="flex flex-wrap "></div>
            <p className="text-center">Dinner Vote</p>
            <div className="mx-auto">
                <div className="inline-flex rounded-md shadow-sm py-4" role="group">
                    <button type="button" className="px-4 py-2 text-sm font-medium border rounded-s-lg focus:z-10 focus:ring-2 bg-gray-800 border-gray-700 text-white hover:text-white hover:bg-gray-700 focus:ring-blue-500 focus:text-white"
                        onClick={() => setSelectedWeek(thisWeekStartDateString)}>This week</button>
                    <button type="button" className="px-4 py-2 text-sm font-medium border-t border-b focus:z-10 focus:ring-2 bg-gray-800 border-gray-700 text-white hover:text-white hover:bg-gray-700 focus:ring-blue-500 focus:text-white"
                        onClick={() => setSelectedWeek(nextWeekStartDateString)}>Next week</button>
                </div>
            </div>

                <DinnerVoteSelect 
                    voteIndex={0}
                    votes={getVotesForWeek()} 
                    meals={meals} 
                    setVotes={getVoteFuncForWeek()} 
                />
                <DinnerVoteSelect 
                    voteIndex={1}
                    votes={getVotesForWeek()} 
                    meals={meals} 
                    setVotes={getVoteFuncForWeek()} 
                />
                <DinnerVoteSelect 
                    voteIndex={2}
                    votes={getVotesForWeek()} 
                    meals={meals} 
                    setVotes={getVoteFuncForWeek()} 
                />

                    <button type="button" 
                        onClick={() => submitVotes()}
                        className="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800" >
                        {voted ? 'Change votes' : 'Submit Votes'}
                    </button>
                    <p className="text-red" >{errorMessage}</p>


        </div>
        </section>

}

export default DinnerVote;
