import { useEffect, useState } from "react"
import { fetchPockeyMoneyBalance, fetchPockeyMoneyTransactions } from "./api/api"
import { LineItem } from "./types"
import PocketMoneyTransactions from "./PocketMoneyTransactions"

interface IPocketMoneyProps {

}

const PocketMoney = (props: IPocketMoneyProps) => {

    const [errorMessage, setErrorMessage] = useState<string>('')
    const [balance, setBalance] = useState<number>(0)
    const [transactions, setTransactions] = useState<LineItem[]>([])

    useEffect(() => {
        async function fetchData() {
            const fetchPockeyMoneyBalanceResponse = await fetchPockeyMoneyBalance();
            if (fetchPockeyMoneyBalanceResponse.errorMessage) {
              setErrorMessage(fetchPockeyMoneyBalanceResponse.errorMessage)
            }
            const roundedBalance = Math.round((fetchPockeyMoneyBalanceResponse.amount + Number.EPSILON) * 100) / 100
            setBalance(roundedBalance)
        }
        async function fetchTransactions() {
            const fetchPocketMoneyTransactionsResponse = await fetchPockeyMoneyTransactions();
            if(fetchPocketMoneyTransactionsResponse.errorMessage) {
                setErrorMessage(fetchPocketMoneyTransactionsResponse.errorMessage)
            }
            setTransactions(fetchPocketMoneyTransactionsResponse.lineEntries)
        }
        fetchData()
        fetchTransactions()
    }, [])

    return <>
    <section className="py-10">
        <div className="container mx-auto">
            <div className="p-5 mb-5 bg-gray-700 group rounded-xl shadow-testimonial h-[120px] w-[120px] mx-auto flex">
                <h3 className="m-auto text-lg font-semibold text-white text-center" >
                    ${balance}
                </h3>
            </div>
        </div>
    </section>
    <PocketMoneyTransactions transactions={transactions} />
    </>
}

export default PocketMoney