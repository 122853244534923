import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
    // If not authenticated, redirect to login page
    if (!sessionStorage.getItem("accessToken")) {
        return <Navigate to="/login" />;
    }

    // Render children if authenticated
    return <Outlet />;
};

export default ProtectedRoute